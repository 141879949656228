@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*.edititemWrapper {
    margin-top: 1vh;
    background: var(--white);
    border-radius: 3vh;
    box-shadow: 4px 4px 10px 0px var(--black);
}*/

/* small or mobile screens */
@media only screen and (max-width: 760px) {
    .edititemModal {
        width: 100%;
        height: 100%;

        background: var(--whiteish);
        overflow: auto;
        padding: 15px;
    }

    .edititem_btnbottom {
        display: none;
    }
}

/* large screen */
@media only screen and (min-width: 760px) {
    .edititemModal {
        max-width: 350px;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

        position: absolute;
        border: 1px solid rgb(204, 204, 204);
        background: var(--whiteish);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
    }

    .edititem_btntop {
        display: none;
    }
}
.edititem_btntop {
    margin-bottom: 10px;
}
.edititem_btnbottom {
    margin-top: 20px;
}

.edititem_wrapper {
    display: flex;
    flex-direction: column;
}

.edititem_header {
    display: flex;
    justify-content: space-between;
    width: 400px;
}
.edititem_headertxt {
    font-size: 25px;
    font-weight: 599;
}
.edititem_inputrow {
    padding: 5px 0px 20px 0px;
}
.edititem_input {
    /*width: 50vw;*/
    width: 100%;
    height: 60px;
    /*border-radius: 5vh 0 0 5vh;*/
    /*padding: 1vh 5vw;*/
    padding-left: 5px;

    font-size: 18px;
}

.edititem_categoryrow {
    display: flex;
    justify-content: space-between;
}

.edititem_imagecontainer {
    position: relative;
    /*width: 300px;
    height: 300px;*/
    margin-bottom: 1em;
}
.edititem_image {
    max-width: 100%;
    width: 100%;
    height: auto;
}
.edititem_deleteimage {
    position: absolute;
    top: 0px;
    right: 0px;
    /*    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);*/

    background-color: white;
    color: black;
    font-size: 20px;
    font-weight: 900;
    padding: 9px 18px;
    border: none;
    cursor: pointer;
    /*border-radius: 5px;*/
    border-radius: 50%;
    border: 1px solid black;
}

.edititem_deleterow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.edititem_deletebutton {
    font-size: 20px;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: rgb(241, 69, 98);
    color: white;
}
.edititem_closebutton {
    font-size: 20px;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: green;
    color: white;
}
.edititem_deletebutton:disabled {
    background-color: grey;
}
.edititem_closebutton:disabled {
    background-color: grey;
}
.edititem_imagewrapper {
    /* forcing the filepond control to a fixed size, and matching the background */
    height: 300px;
    max-height: 300px;
    min-height: 300px;

    margin: auto;   /* center our children */
}

.haveimage {
    display: inline-block;
    position: relative;
}

.haveimage button {
    position: absolute;
    top: 5px;
    right: 5px;
}
.close-icon
{
  display:block;
  box-sizing:border-box;
  width:20px;
  height:20px;
  border-width:3px;
  border-style: solid;
  border-color:red;
  border-radius:100%;
  background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);
  background-color:red;
  box-shadow:0px 0px 5px 2px rgba(0,0,0,0.5);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

:root {
    --green: #186527;
    --palegreen: #c8e2cd;

    --white: #fff;
    --whiteish: #ecefec;
    --grey: #707070;
    --black: #2e2e2e;
}

* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    color: #2e2e2e;
    color: var(--black);
}

.inputWrapper {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: calc(5vh + 60px);
    margin: auto;
    padding: 2.5vh 5vw;

    border-bottom: solid 3px #186527;

    border-bottom: solid 3px var(--green);
    background: #ecefec;
    background: var(--whiteish);
}

.inputNewWrapper {
    display: flex;
    justify-content: space-between;
}

.inputNew {
    width: 75vw;
    height: 60px;
    padding: 1vh 5vw;
    outline: none;
    border: none;
    font-size: 18px;
    /*background: var(--grey);*/
    background: #ecefec;
    background: var(--whiteish);
}

.submit {
    height: 60px;
    width: 60px;
    /*border-radius: 0 5vh 5vh 0;*/
    border-radius: 50%;
    border: none;
    padding: 1vh 2.5vw;
    outline: none;
    background: #c8e2cd;
    background: var(--palegreen);

    font-size: 18px;
    font-weight: bold;
    color: #186527;
    color: var(--green);
}

.space {
    height: calc(7vh + 60px);
}
.smallspace {
    height: calc(3vh + 30px);
}

.itemWrapper {
    display: flex;
    width: 90vw;
    /*height: 60px;*/
    height: auto;
    /*padding: 1vh 5vw;*/

    margin: 2.5vh 5vw;

    font-size: 18px;
}
.itemWrapper_mpm {
    /* mealPlanMode */
    display: flex;
    width: 90vw;
    height: 60px;
    /*padding: 1vh 5vw;*/
    /*margin: 2.5vh 5vw;*/

    font-size: 18px;
}

.itemImage {
    width: 60px;
    height: 60px;
    background: #c8e2cd;
    background: var(--palegreen);
    border-radius: 50%;
}

.itemName {
    height: auto;
    width: 73vw;
    margin: auto;
    margin-left: 2vw;
}
.safeway {
    height: auto;
    margin: auto;
}
.clearItem {
    font-family: 'Ubuntu Mono';
    font-size: 40px;
    /*line-height: 0;*/
    margin: auto;
    margin-top: 0.75vh;
}

.App {
    background: #ecefec;
    background: var(--whiteish);
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    padding: 0px 0px 30px 0px;
    margin: 0;
}

.moreButton {
    position: fixed;
    bottom: 2vh;
    right: 2vh;
    background: #186527;
    background: var(--green);
    background-image: url(/static/media/menu.fdc5eb04.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
}

.suggestion {
    margin: auto;
}

.suggestionName {
    margin: auto;
    margin-left: 2vw;
}

.suggestionsWrap {
    margin-top: 1vh;
    background: #fff;
    background: var(--white);
    border-radius: 3vh;
    box-shadow: 4px 4px 10px 0px #2e2e2e;
    box-shadow: 4px 4px 10px 0px var(--black);
}

/**
 * Edit
 */

.mfu_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 760px) {
    .itemName {
        width: 70vw;
    }
}
@media only screen and (max-width: 615px) {
    .itemName {
        width: 65vw;
    }
}
@media only screen and (max-width: 470px) {
    .inputNew {
        width: 80vw;
    }
    .itemName {
        width: 60vw;
    }
}
@media only screen and (max-width: 400px) {
    .itemName {
        width: 45vw;
    }
}

.itemtransition-enter {
    /*opacity: 0.01;*/
    background-color: rgba(0, 182, 0, 0.5);
}

.itemtransition-enter-active {

    background-color: #ecefec;
    /*transition: background-color 10000 ease-in;*/

    /*opacity: 1;*/
    /*transition: opacity 10000ms ease-in;*/
    -webkit-transition: 2000ms ease-in;
    transition: 2000ms ease-in;

    /*background-color: red;*/
    
}

.itemtransition-enter-done {
    background-color: #ecefec;
}



.mpm_wrapper {
    /*width: 90vw;
    margin: 2.5vh 5vw;*/
    padding-top: 15px;
}

.mpm_listitem {
    display: flex;
    align-items: center;
    width: 90vw;
    /*height: 60px;*/
    /*padding: 1vh 5vw;*/
    /*margin: 2.5vh 5vw;*/

    padding-bottom: 15px;
    font-size: 19px;
}
.mpm_listitem_x {
    font-family: 'Ubuntu Mono';
    font-weight: 600;
    font-size: 30px;
    color: black;
    padding-left: 7vw;
    padding-bottom: 5px; /* to bring the small case 'x' in line with the user text */
    cursor: pointer;
}
.mpm_listitem_name {
    padding-left: 3vw;
}

/**
 * Meal Plan
 */
.mp_week {
    font-size: 18px;
    margin-bottom: 85px;
}
.mp_week_header {
    border-bottom: solid #186527 4px;
    margin-bottom: 41px;
    position: relative;
}
.mp_day {
    text-align: center;
    text-transform: uppercase;
    color: #186527;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 9px;
}
.mp_title {
    text-align: center;
    margin-bottom: 9px;
}
.mp_edittitle_form {
    text-align: center;
}
.mp_edittitle {
    margin-bottom: 9px;
    font-size: xx-large;
    width: 80%;
    text-align: center;
}
.mp_week_additembtn {
    position: absolute;
    top: 5px;
    right: 5px;

    /*display:block;*/
    box-sizing: border-box;
    width: 49px;
    height: 49px;

    /* center text */
    text-align: center;
    vertical-align: middle;
    line-height: 49px; /* The same as your div height */

    font-size: 45px;
    color: #186527;

    /*
  border-width:3px;
  border-style: solid;
  border-color:red;*/
    border-radius: 100%;

    /*background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);*/
    background-color: #c8e2cd;
    /*box-shadow:0px 0px 5px 2px rgba(0,0,0,0.5);*/
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    cursor: pointer;
}

.drawer {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    background: var(--whiteish);
}
.drawer_content {
    width: 100%;
    height: 100%;
    overflow-y: auto; /* so that it can scroll */
}
.drawer_newitem_wrapper {
    width: 90vw;
    margin: 2.5vh 5vw;
}
.drawer_close {
    position: fixed;
    bottom: 2vh;
    right: 2vh;

    /* center text */
    text-align: center;
    vertical-align: middle;
    line-height: 43px; /* The same as your div height - although slightly lower becaues of the lower case x */

    font-size: 40px;
    background-color: #c8e2cd;
    border-radius: 100%;

    box-sizing: border-box;
    width: 49px;
    height: 49px;

    cursor: pointer;
}

/*
.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 0.75s;
    animation-name: animatebottom;
    animation-duration: 0.75s;
}
@-webkit-keyframes animatebottom {
    from {
        bottom: -100%;
        opacity: 1;
    }
    to {
        bottom: 0px;
        opacity: 1;
    }
}
@keyframes animatebottom {
    from {
        bottom: -100%;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}

.animate-out {
    position: relative;
    -webkit-animation-name: animateout;
    -webkit-animation-duration: 0.75s;
    animation-name: animateout;
    animation-duration: 0.75s;

    z-index: 0;
}
@-webkit-keyframes animateout {
    from {
        bottom: 0px;
        opacity: 1;
    }
    to {
        bottom: -100%px;
        opacity: 0;
    }
}
@keyframes animateout {
    from {
        bottom: 0px;
        opacity: 1;
    }
    to {
        bottom: -100%;
        opacity: 0;
    }
}
*/


.emp_meallist {
    font-size: 15pt;
    line-height: 30pt;
}
.google_btn {
    margin-bottom: 10px;

    cursor: pointer;

    display: inline-block;
    line-height: normal;

    min-height: 40px;
    width: 100%;
    padding: 8px 16px;
    background-color: #fff;

    border: none;
    border-radius: 2px;
    color: #000;

    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}
.google_icon_wrapper {
    display: table-cell;
    vertical-align: middle;

    padding-left: 4px;

    height: 18px;
    width: 18px;
}
.google_icon {
    height: 18px;
    width: 18px;
}
.google_text {
    display: table-cell;
    vertical-align: middle;
    width: 100%;

    color: #757575;
    text-transform: none;
    padding-left: 8px;

    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.login {
    /*    position: fixed;
    min-width: 200px;
    max-width: 200px;
    top: calc(50% - 22px);
    left: calc(50% - 80px);*/
    width: 70%;
}

.mktg {
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: var(--green);

    display: flex;
    align-items: center;
    justify-content: center;
}
.mktg_horiz {
    display: flex;
    flex-direction: row;
}
.mktg_left {
    width: 40vw;
    margin: 0px 20px 0px 20px;
}
.mktg_right {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}
.mktg_left_centered {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.mktg_inputWrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--green);
    width: 100vw;
    height: calc(5vh + 60px);
    margin: auto;
    padding: 2.5vh 5vw;
}
.mktg_inputNew {
    display: flex;
    width: 100%;
    background-color: white;
    height: 60px;
    padding: 0;
    border-radius: 30px;

    margin-bottom: 4vh;
}
.mktg_itemWrapper {
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    border: 1px solid #dedede;
    padding: 0 15px 0 0;
    background: var(--white);
    margin: 1.2vh auto;

    font-size: 18px;
}

.mktg_right_title {
    font-family: 'Roboto', sans-serif;
    font-size: 90px;
    color: white;
    margin-bottom: 15px;
    font-weight: bold;
}
.mktg_right_subtext {
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 19px;
    color: white;
    margin-bottom: 10px;
}

.mktg_itemWrapper .itemImage {
    padding: 30px;
    margin-left: -2px;
}

.mktg_inputNew .submit {
    margin: 0;
    padding: 18px 25px;
}

@media only screen and (max-width: 700px) {
    .mktg_horiz {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
    .mktg_left {
        order: 2;
        margin-top: 40px;
        margin-bottom: 20px;
        width: 80%;
    }
    .mktg_left_centered {
        width: 100%;
    }
}

