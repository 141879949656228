.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.login {
    /*    position: fixed;
    min-width: 200px;
    max-width: 200px;
    top: calc(50% - 22px);
    left: calc(50% - 80px);*/
    width: 70%;
}

.mktg {
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: var(--green);

    display: flex;
    align-items: center;
    justify-content: center;
}
.mktg_horiz {
    display: flex;
    flex-direction: row;
}
.mktg_left {
    width: 40vw;
    margin: 0px 20px 0px 20px;
}
.mktg_right {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}
.mktg_left_centered {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.mktg_inputWrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--green);
    width: 100vw;
    height: calc(5vh + 60px);
    margin: auto;
    padding: 2.5vh 5vw;
}
.mktg_inputNew {
    display: flex;
    width: 100%;
    background-color: white;
    height: 60px;
    padding: 0;
    border-radius: 30px;

    margin-bottom: 4vh;
}
.mktg_itemWrapper {
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    border: 1px solid #dedede;
    padding: 0 15px 0 0;
    background: var(--white);
    margin: 1.2vh auto;

    font-size: 18px;
}

.mktg_right_title {
    font-family: 'Roboto', sans-serif;
    font-size: 90px;
    color: white;
    margin-bottom: 15px;
    font-weight: bold;
}
.mktg_right_subtext {
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 19px;
    color: white;
    margin-bottom: 10px;
}

.mktg_itemWrapper .itemImage {
    padding: 30px;
    margin-left: -2px;
}

.mktg_inputNew .submit {
    margin: 0;
    padding: 18px 25px;
}

@media only screen and (max-width: 700px) {
    .mktg_horiz {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
    .mktg_left {
        order: 2;
        margin-top: 40px;
        margin-bottom: 20px;
        width: 80%;
    }
    .mktg_left_centered {
        width: 100%;
    }
}
