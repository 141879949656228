/*.edititemWrapper {
    margin-top: 1vh;
    background: var(--white);
    border-radius: 3vh;
    box-shadow: 4px 4px 10px 0px var(--black);
}*/

/* small or mobile screens */
@media only screen and (max-width: 760px) {
    .edititemModal {
        width: 100%;
        height: 100%;

        background: var(--whiteish);
        overflow: auto;
        padding: 15px;
    }

    .edititem_btnbottom {
        display: none;
    }
}

/* large screen */
@media only screen and (min-width: 760px) {
    .edititemModal {
        max-width: 350px;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        position: absolute;
        border: 1px solid rgb(204, 204, 204);
        background: var(--whiteish);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
    }

    .edititem_btntop {
        display: none;
    }
}
.edititem_btntop {
    margin-bottom: 10px;
}
.edititem_btnbottom {
    margin-top: 20px;
}

.edititem_wrapper {
    display: flex;
    flex-direction: column;
}

.edititem_header {
    display: flex;
    justify-content: space-between;
    width: 400px;
}
.edititem_headertxt {
    font-size: 25px;
    font-weight: 599;
}
.edititem_inputrow {
    padding: 5px 0px 20px 0px;
}
.edititem_input {
    /*width: 50vw;*/
    width: 100%;
    height: 60px;
    /*border-radius: 5vh 0 0 5vh;*/
    /*padding: 1vh 5vw;*/
    padding-left: 5px;

    font-size: 18px;
}

.edititem_categoryrow {
    display: flex;
    justify-content: space-between;
}

.edititem_imagecontainer {
    position: relative;
    /*width: 300px;
    height: 300px;*/
    margin-bottom: 1em;
}
.edititem_image {
    max-width: 100%;
    width: 100%;
    height: auto;
}
.edititem_deleteimage {
    position: absolute;
    top: 0px;
    right: 0px;
    /*    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);*/

    background-color: white;
    color: black;
    font-size: 20px;
    font-weight: 900;
    padding: 9px 18px;
    border: none;
    cursor: pointer;
    /*border-radius: 5px;*/
    border-radius: 50%;
    border: 1px solid black;
}

.edititem_deleterow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.edititem_deletebutton {
    font-size: 20px;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: rgb(241, 69, 98);
    color: white;
}
.edititem_closebutton {
    font-size: 20px;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: green;
    color: white;
}
.edititem_deletebutton:disabled {
    background-color: grey;
}
.edititem_closebutton:disabled {
    background-color: grey;
}
.edititem_imagewrapper {
    /* forcing the filepond control to a fixed size, and matching the background */
    height: 300px;
    max-height: 300px;
    min-height: 300px;

    margin: auto;   /* center our children */
}

.haveimage {
    display: inline-block;
    position: relative;
}

.haveimage button {
    position: absolute;
    top: 5px;
    right: 5px;
}
.close-icon
{
  display:block;
  box-sizing:border-box;
  width:20px;
  height:20px;
  border-width:3px;
  border-style: solid;
  border-color:red;
  border-radius:100%;
  background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);
  background-color:red;
  box-shadow:0px 0px 5px 2px rgba(0,0,0,0.5);
  transition: all 0.3s ease;
}
