.mpm_wrapper {
    /*width: 90vw;
    margin: 2.5vh 5vw;*/
    padding-top: 15px;
}

.mpm_listitem {
    display: flex;
    align-items: center;
    width: 90vw;
    /*height: 60px;*/
    /*padding: 1vh 5vw;*/
    /*margin: 2.5vh 5vw;*/

    padding-bottom: 15px;
    font-size: 19px;
}
.mpm_listitem_x {
    font-family: 'Ubuntu Mono';
    font-weight: 600;
    font-size: 30px;
    color: black;
    padding-left: 7vw;
    padding-bottom: 5px; /* to bring the small case 'x' in line with the user text */
    cursor: pointer;
}
.mpm_listitem_name {
    padding-left: 3vw;
}

/**
 * Meal Plan
 */
.mp_week {
    font-size: 18px;
    margin-bottom: 85px;
}
.mp_week_header {
    border-bottom: solid #186527 4px;
    margin-bottom: 41px;
    position: relative;
}
.mp_day {
    text-align: center;
    text-transform: uppercase;
    color: #186527;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 9px;
}
.mp_title {
    text-align: center;
    margin-bottom: 9px;
}
.mp_edittitle_form {
    text-align: center;
}
.mp_edittitle {
    margin-bottom: 9px;
    font-size: xx-large;
    width: 80%;
    text-align: center;
}
.mp_week_additembtn {
    position: absolute;
    top: 5px;
    right: 5px;

    /*display:block;*/
    box-sizing: border-box;
    width: 49px;
    height: 49px;

    /* center text */
    text-align: center;
    vertical-align: middle;
    line-height: 49px; /* The same as your div height */

    font-size: 45px;
    color: #186527;

    /*
  border-width:3px;
  border-style: solid;
  border-color:red;*/
    border-radius: 100%;

    /*background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);*/
    background-color: #c8e2cd;
    /*box-shadow:0px 0px 5px 2px rgba(0,0,0,0.5);*/
    transition: all 0.3s ease;

    cursor: pointer;
}

.drawer {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    background: var(--whiteish);
}
.drawer_content {
    width: 100%;
    height: 100%;
    overflow-y: auto; /* so that it can scroll */
}
.drawer_newitem_wrapper {
    width: 90vw;
    margin: 2.5vh 5vw;
}
.drawer_close {
    position: fixed;
    bottom: 2vh;
    right: 2vh;

    /* center text */
    text-align: center;
    vertical-align: middle;
    line-height: 43px; /* The same as your div height - although slightly lower becaues of the lower case x */

    font-size: 40px;
    background-color: #c8e2cd;
    border-radius: 100%;

    box-sizing: border-box;
    width: 49px;
    height: 49px;

    cursor: pointer;
}

/*
.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 0.75s;
    animation-name: animatebottom;
    animation-duration: 0.75s;
}
@-webkit-keyframes animatebottom {
    from {
        bottom: -100%;
        opacity: 1;
    }
    to {
        bottom: 0px;
        opacity: 1;
    }
}
@keyframes animatebottom {
    from {
        bottom: -100%;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}

.animate-out {
    position: relative;
    -webkit-animation-name: animateout;
    -webkit-animation-duration: 0.75s;
    animation-name: animateout;
    animation-duration: 0.75s;

    z-index: 0;
}
@-webkit-keyframes animateout {
    from {
        bottom: 0px;
        opacity: 1;
    }
    to {
        bottom: -100%px;
        opacity: 0;
    }
}
@keyframes animateout {
    from {
        bottom: 0px;
        opacity: 1;
    }
    to {
        bottom: -100%;
        opacity: 0;
    }
}
*/


.emp_meallist {
    font-size: 15pt;
    line-height: 30pt;
}