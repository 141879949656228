@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');

:root {
    --green: #186527;
    --palegreen: #c8e2cd;

    --white: #fff;
    --whiteish: #ecefec;
    --grey: #707070;
    --black: #2e2e2e;
}

* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    color: var(--black);
}

.inputWrapper {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: calc(5vh + 60px);
    margin: auto;
    padding: 2.5vh 5vw;

    border-bottom: solid 3px var(--green);
    background: var(--whiteish);
}

.inputNewWrapper {
    display: flex;
    justify-content: space-between;
}

.inputNew {
    width: 75vw;
    height: 60px;
    padding: 1vh 5vw;
    outline: none;
    border: none;
    font-size: 18px;
    /*background: var(--grey);*/
    background: var(--whiteish);
}

.submit {
    height: 60px;
    width: 60px;
    /*border-radius: 0 5vh 5vh 0;*/
    border-radius: 50%;
    border: none;
    padding: 1vh 2.5vw;
    outline: none;
    background: var(--palegreen);

    font-size: 18px;
    font-weight: bold;
    color: var(--green);
}

.space {
    height: calc(7vh + 60px);
}
.smallspace {
    height: calc(3vh + 30px);
}

.itemWrapper {
    display: flex;
    width: 90vw;
    /*height: 60px;*/
    height: auto;
    /*padding: 1vh 5vw;*/

    margin: 2.5vh 5vw;

    font-size: 18px;
}
.itemWrapper_mpm {
    /* mealPlanMode */
    display: flex;
    width: 90vw;
    height: 60px;
    /*padding: 1vh 5vw;*/
    /*margin: 2.5vh 5vw;*/

    font-size: 18px;
}

.itemImage {
    width: 60px;
    height: 60px;
    background: var(--palegreen);
    border-radius: 50%;
}

.itemName {
    height: auto;
    width: 73vw;
    margin: auto;
    margin-left: 2vw;
}
.safeway {
    height: auto;
    margin: auto;
}
.clearItem {
    font-family: 'Ubuntu Mono';
    font-size: 40px;
    /*line-height: 0;*/
    margin: auto;
    margin-top: 0.75vh;
}

.App {
    background: var(--whiteish);
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    padding: 0px 0px 30px 0px;
    margin: 0;
}

.moreButton {
    position: fixed;
    bottom: 2vh;
    right: 2vh;
    background: var(--green);
    background-image: url('menu.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
}

.suggestion {
    margin: auto;
}

.suggestionName {
    margin: auto;
    margin-left: 2vw;
}

.suggestionsWrap {
    margin-top: 1vh;
    background: var(--white);
    border-radius: 3vh;
    box-shadow: 4px 4px 10px 0px var(--black);
}

/**
 * Edit
 */

.mfu_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 760px) {
    .itemName {
        width: 70vw;
    }
}
@media only screen and (max-width: 615px) {
    .itemName {
        width: 65vw;
    }
}
@media only screen and (max-width: 470px) {
    .inputNew {
        width: 80vw;
    }
    .itemName {
        width: 60vw;
    }
}
@media only screen and (max-width: 400px) {
    .itemName {
        width: 45vw;
    }
}

.itemtransition-enter {
    /*opacity: 0.01;*/
    background-color: rgba(0, 182, 0, 0.5);
}

.itemtransition-enter-active {

    background-color: #ecefec;
    /*transition: background-color 10000 ease-in;*/

    /*opacity: 1;*/
    /*transition: opacity 10000ms ease-in;*/
    transition: 2000ms ease-in;

    /*background-color: red;*/
    
}

.itemtransition-enter-done {
    background-color: #ecefec;
}
